@font-face {
  font-family: "Furious Styles";
  src: url("../assets/fonts/FuriousStyles/fonts/furiousstylesdemo-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/FuriousStyles/fonts/furiousstylesdemo-webfont.woff")
      format("woff"),
    url("../assets/fonts/FuriousStyles/fonts/FuriousStylesDemo.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Black";
  src: url("../assets/fonts/ProductSans/fonts/productsans-black-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-black-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-Black.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Black Italic";
  src: url("../assets/fonts/ProductSans/fonts/productsans-blackitalic-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-blackitalic-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-BlackItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Bold";
  src: url("../assets/fonts/ProductSans/fonts/productsans-bold-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-bold-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-Bold.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Bold Italic";
  src: url("../assets/fonts/ProductSans/fonts/productsans-bolditalic-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-bolditalic-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-BoldItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Italic";
  src: url("../assets/fonts/ProductSans/fonts/productsans-italic-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-italic-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-Italic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Light";
  src: url("../assets/fonts/ProductSans/fonts/productsans-light-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-light-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-Light.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Light Italic";
  src: url("../assets/fonts/ProductSans/fonts/productsans-lightitalic-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-lightitalic-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-LightItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Medium";
  src: url("../assets/fonts/ProductSans/fonts/productsans-medium-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-medium-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-Medium.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Medium Italic";
  src: url("../assets/fonts/ProductSans/fonts/productsans-mediumitalic-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-mediumitalic-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-MediumItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/ProductSans/fonts/productsans-regular-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-regular-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Thin";
  src: url("../assets/fonts/ProductSans/fonts/productsans-thin-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-thin-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-Thin.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans Thin Italic";
  src: url("../assets/fonts/ProductSans/fonts/productsans-thinitalic-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/ProductSans/fonts/productsans-thinitalic-webfont.woff")
      format("woff"),
    url("../assets/fonts/ProductSans/fonts/ProductSans-ThinItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --dark-bg: black;
  --dark-gradient-start: rgb(29, 35, 57);
  --dark-gradient-stop: var(--dark-bg);
  --dark-text-color: rgb(167, 24, 20);
  --light-bg: white;
  --light-gradient-stop: rgb(99, 99, 99);
  --light-gradient-start: var(--light-bg);
  --light-text-color: rgb(28, 159, 223);
  --light-slide-color: rgb(99, 99, 99);
  --background: var(--dark-bg);
  --text-color: var(--dark-text-color);
  --gradient-start: var(--dark-gradient-start);
  --gradient-stop: var(--dark-gradient-stop);
  --secondary-text-color: var(--light-bg);
  --slide-color: var(--light-bg);
}

/* General Styles */

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a.amharic {
  color: var(--text-color);
  font-size: 1.14rem;
}

a.amharic:hover,
a.highlight:hover {
  color: var(--secondary-text-color);
}

.about,
.contact,
.experience,
.projects,
.showcase-area {
  padding: 100px 0;
  filter: blur(0);
}

.App {
  overflow: hidden;
}

.back-btn {
  margin-right: 4rem;
  display: none;
}

body {
  background-color: var(--background);
  background-attachment: fixed;
  background-image: linear-gradient(
    180deg,
    var(--gradient-start) 20%,
    var(--gradient-stop) 100%
  );
  background-repeat: no-repeat;
  overflow-x: hidden;
  color: var(--secondary-text-color);
  font-family: "Product Sans";
}

body.open header {
  max-height: 505px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  background-color: var(--gradient-stop);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

body.open .overlay {
  opacity: 1;
  pointer-events: auto;
}

body.open .pages {
  opacity: 1;
}

body.open .pages::after {
  opacity: 0.7;
  width: 100%;
}

.error-message {
  font-family: "Furious Styles";
  font-size: calc(100px + 5vw);
  color: var(--text-color);
}

.error-page {
  width: 100vw;
  height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
}

figure {
  text-align: center;
}

footer {
  text-align: center;
  padding: 3em 1em 2.5em 1em;
  border-top: 2px solid var(--text-color);
}

.grain {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background: url("../assets/images/grain.gif");
  z-index: 0;
  opacity: 0.1;
  pointer-events: none;
}

.highlight {
  color: var(--text-color);
  font-family: "Product Sans Black";
}

html {
  scroll-behavior: smooth;
}

i {
  color: var(--text-color);
  cursor: pointer;
}

i:hover {
  color: var(--secondary-text-color);
}

img {
  width: 100%;
  color: black;
}

a::selection,
button::selection,
span::selection,
.last-name::selection,
.name::selection,
h1::selection,
h3::selection {
  background-color: var(--secondary-text-color);
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
}

ul {
  list-style: none;
}

.list {
  list-style: inside;
}

.list li::marker {
  color: var(--text-color);
  font-size: 1rem;
}

.toggle-btn-container {
  width: 24px;
  height: 24px;
}

::selection,
.first-name::selection {
  background-color: var(--text-color);
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-text-color);
}

/* Header */

.hamburger {
  padding: 1rem 0;
  height: 12px;
  width: 25px;
  box-sizing: content-box;
  cursor: pointer;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 505px;
  max-height: 100px;
  overflow: hidden;
  background-color: var(--background);
}

header.scrolled {
  box-shadow: 0 8px 32px 0.1px rgba(0, 0, 0, 0.37);
  height: 485px;
  max-height: 80px;
}

header .name {
  height: 100px;
  line-height: 100px;
}

header.scrolled .name {
  height: 80px;
  line-height: 80px;
}

header nav {
  justify-content: space-between;
}

.name {
  font-size: 1.45rem;
  color: var(--text-color);
  font-family: "Product Sans Black";
  letter-spacing: 5px;
  margin-right: 1.5rem;
  transition: 0.3s;
  width: 33.3%;
}

nav.container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.nav-link {
  margin: 1.5rem 0;
  color: var(--text-color);
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.5rem;
  font-family: "Product Sans Bold";
  transition: 0.3s;
}

.nav-link:hover,
.nav-link.active {
  color: var(--secondary-text-color);
}

.pages {
  display: flex;
  flex: 1 1;
  align-items: center;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;
  order: 3;
  padding: 0.5rem 0 2rem;
  position: relative;
  transition: 0.3s;
}

.pages::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 0.5px;
  background-color: var(--text-color);
  opacity: 0;
  top: -0.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.side-buttons {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
}

.side-buttons .toggle-btn-container {
  width: 50px;
}

/* Showcase Styles */

.first-name,
.last-name {
  font-family: "Furious Styles";
}

.last-name {
  color: var(--text-color);
}

.showcase-area .container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 4rem;
}

.showcase-area {
  padding: 100px 0;
}

.showcase-btns {
  align-items: center;
  display: flex;
  margin-top: 3.5rem;
}

.showcase-btns .btn {
  margin-right: 1.5rem;
}

.showcase-image {
  max-width: 75%;
}

.showcase-image {
  position: relative;
  overflow: hidden;
}

.showcase-image img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.showcase-image {
  aspect-ratio: 0.73/1;
}

.showcase-image .full {
  position: static;
}

.showcase-image .asher {
  z-index: 2;
}

.showcase-image .bg-color {
  position: relative;
  z-index: 1;
}

.showcase-image .bg {
  z-index: 1;
}

.showcase-image .comics-code {
  z-index: 3;
}

.showcase-image .issue-no {
  z-index: 3;
}

.showcase-image .plastic {
  z-index: 4;
  mix-blend-mode: screen;
}

.showcase-image .shine {
  width: 1000px;
  height: 100px;
  margin-left: -100px;
  transform: rotate(30deg);
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  position: absolute;
  animation: shine 8s ease-in-out infinite;
  z-index: 5;
}

.showcase-info {
  width: 100%;
  margin-top: 1rem;
}

/* About Styles */

.about .container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  row-gap: 2rem;
}

.about-image {
  width: 75%;
  aspect-ratio: 1/1;
}

.about-info {
  grid-row: 1;
}

/* Experience Styles */

.experience-background {
  background-color: var(--slide-color);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: 1;
}

.experience-card {
  border-radius: 15px;
  overflow: hidden;
  background-color: var(--gradient-start);
  display: grid;
  aspect-ratio: 9/13;
}

.experience-description {
  padding: 1.1rem 1.6rem;
  overflow: auto;
  touch-action: pan-y;
}

.experience-description h1 {
  color: var(--text-color);
  font-family: "Product Sans Bold";
  text-transform: capitalize;
  font-size: 1rem;
}

.experience-image {
  display: flex;
  align-items: center;
  width: 60%;
  height: 100px;
}

.experience-gallery {
  margin-top: 120px;
}

/* Projects Styles */

.filter-btn {
  display: inline-block;
  color: var(--text-color);
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 0.6rem;
  font-family: "Product Sans Bold";
  letter-spacing: 4px;
  text-transform: uppercase;
}

.filter-btn:hover {
  color: var(--secondary-text-color);
}

.filter-btns {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.magnify {
  font-size: 1.1rem;
  margin: 0 0.4rem;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0;
}

.project-card {
  border-radius: 15px;
  overflow: hidden;
  background-color: var(--gradient-start);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: grid;
  grid-template-rows: 1fr auto;
  aspect-ratio: 3/2;
}

.project-description {
  padding: 0.6rem;
  display: flex;
  justify-content: space-between;
}

.project-description h3 {
  font-size: 0.65rem;
  color: var(--secondary-text-color);
  font-family: "Product Sans Bold";
  text-transform: capitalize;
}

.project-image {
  position: relative;
  overflow: hidden;
}

.project-image-container {
  position: relative;
  display: flex;
  aspect-ratio: 3/2;
}

.project-image img {
  width: 125%;
  height: 125%;
  object-fit: cover;
}

.project-image:hover .magnify {
  opacity: 1;
}

.project-image:hover .project-overlay {
  pointer-events: auto;
  background-color: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.project-overlay {
  position: absolute;
  width: 105%;
  height: 105%;
  top: 0;
  left: 0;
  background-color: transparent;
  backdrop-filter: 0;
  -webkit-backdrop-filter: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.projects-header {
  display: grid;
  align-items: flex-end;
  margin-bottom: 4rem;
}

.projects-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 2rem;
}

.projects-title {
  padding-bottom: 0;
  grid-row: 1;
}

/* Project Detail styles */
.project-desc-title {
  font-family: "Furious Styles";
  color: var(--text-color);
}

.project-desc-image .container {
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 2rem;
}

.project-desc-images .container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  row-gap: 2rem;
}

.project-desc-image figure,
.project-desc-images figure {
  width: 75%;
  margin: auto;
}

/* Contact Styles */

.contact .container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  row-gap: 4rem;
}

.socials {
  display: flex;
  justify-content: space-evenly;
}

.social-link {
  color: var(--text-color);
  display: flex;
  font-size: 1.5rem;
}

.social-link:hover {
  color: var(--secondary-text-color);
}

/* Reusable Styles */

body.open,
body.open-modal {
  overflow: hidden;
}

.btn {
  display: inline-block;
  padding: 0.8rem 1.6rem;
  border-radius: 15px;
  background-color: var(--text-color);
  color: var(--secondary-text-color);
  font-size: 0.8rem;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  transition: 0.3s;
  min-width: 100px;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--secondary-text-color);
  color: var(--text-color);
}

.btn.secondary-btn {
  position: relative;
  background-color: transparent;
  color: var(--text-color);
  font-family: "Product Sans Black";
  font-size: 0.9rem;
  letter-spacing: 0px;
  border-bottom: 2.5px solid;
  border-radius: 0px;
  padding: 0.15rem 0;
  min-width: 0;
}

.btn.secondary-btn:hover {
  color: var(--secondary-text-color);
}

.btn.secondary-btn::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2.5px;
  bottom: -2.5px;
  left: 0;
  background-color: var(--secondary-text-color);
  transition: 0.3s ease-in-out;
}

.btn.secondary-btn:hover::before {
  width: 100%;
}

.btn.secondary-btn.sm {
  font-size: 0.55rem;
  border-bottom-width: 2px;
}

.btn.secondary-btn.sm::before {
  height: 2px;
  bottom: -2px;
}

.container {
  position: relative;
  width: 100%;
  max-width: 90rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.heading {
  font-size: 2rem;
  line-height: 0.5;
}

.sub-heading {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  border-left: 3px solid var(--text-color);
  padding: 0.13rem 0.75rem;
  font-family: "Product Sans";
  margin-bottom: 0.7rem;
}

.text {
  font-size: 0.94rem;
  line-height: 2;
  margin: 1rem 0 1.54rem;
}

/* color theme */

@media (prefers-color-scheme: light) {
  :root,
  ::backdrop {
    --background: var(--light-bg);
    --text-color: var(--light-text-color);
    --secondary-text-color: var(--dark-bg);
    --gradient-start: var(--light-gradient-start);
    --gradient-stop: var(--light-gradient-stop);
    --slide-color: var(--light-bg);
  }

  .grain {
    filter: invert();
  }
}

body.light {
  --background: var(--light-bg);
  --text-color: var(--light-text-color);
  --secondary-text-color: var(--dark-bg);
  --gradient-start: var(--light-gradient-start);
  --gradient-stop: var(--light-gradient-stop);
  --slide-color: var(--light-gradient-stop);
}

body.dark {
  --background: var(--dark-bg);
  --text-color: var(--dark-text-color);
  --gradient-start: var(--dark-gradient-start);
  --gradient-stop: var(--dark-gradient-stop);
  --secondary-text-color: var(--light-bg);
  --slide-color: var(--light-bg);
}

.grain.light {
  filter: invert();
}

/* Animations */
@keyframes shine {
  0%,
  100% {
    margin-top: -100px;
  }
  50% {
    margin-top: 800px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .container {
    padding: 0 1.5rem;
  }

  .experience-description h1 {
    font-size: 1.5rem;
  }

  .experience-image {
    height: 150px;
  }

  .heading {
    font-size: 3.5rem;
  }

  .social-link {
    font-size: 2.5rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .about .container {
    grid-template-columns: 1fr 1fr;
    justify-items: normal;
  }

  .about,
  .contact,
  .experience,
  .projects,
  .showcase-area {
    padding: 8rem 0;
  }

  .about-info {
    grid-row: auto;
  }

  .about-image {
    width: 90%;
  }

  .btn.secondary-btn.sm {
    font-size: 0.68rem;
  }

  .experience-background {
    grid-row: auto;
  }

  .experience-card {
    grid-template-columns: 2fr 0.5fr;
  }

  .experience-image {
    width: 90%;
    height: auto;
  }

  .filter-btn {
    margin-left: 1.3rem;
  }

  .hamburger {
    display: none;
  }

  .heading {
    font-size: 3rem;
  }

  nav.container {
    flex-wrap: nowrap;
  }

  .nav-link {
    margin: 0 0.7rem;
    font-size: 0.55rem;
  }

  .pages {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: normal;
    flex-basis: auto;
    order: 0;
    padding: 0;
  }

  .project-desc-images .container {
    grid-template-columns: 1fr 1fr;
    justify-items: normal;
  }

  .project-description h3 {
    font-size: 0.7rem;
  }

  .projects-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  .projects-gallery {
    grid-template-columns: repeat(auto-fill, minmax(187.5px, 1fr));
  }

  .showcase-area .container {
    grid-template-columns: 1fr 0.6fr;
  }

  .showcase-image {
    max-width: max-content;
  }

  .side-buttons .toggle-btn-container {
    width: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .back-btn {
    display: inline;
  }

  .btn {
    font-size: 0.85rem;
  }

  .contact .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .empty .container {
    height: calc(15vh - 80px);
  }

  .experience-card {
    aspect-ratio: auto;
    height: 530px;
  }

  .experience-description h1 {
    font-size: 2rem;
  }

  .heading {
    font-size: 4rem;
  }

  .filter-btn {
    font-size: 0.75rem;
  }

  .nav-link {
    font-size: 0.7rem;
    margin: 0 1rem;
  }

  .projects-gallery {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  }

  .showcase-area .container {
    grid-template-columns: 1fr 0.5fr;
  }

  .sub-heading {
    font-size: 0.85rem;
  }

  .text {
    font-size: 1rem;
  }

  .showcase-area,
  .about,
  .experience,
  .projects,
  .contact {
    padding: 90px 100px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  a.amharic {
    font-size: 1.2rem;
  }

  .filter-btn {
    font-size: 0.85rem;
  }

  header .name {
    height: 120px;
    line-height: 120px;
  }

  .name {
    font-size: 1.5rem;
  }

  .nav-link {
    font-size: 0.75rem;
  }

  .projects-gallery {
    grid-template-columns: repeat(auto-fill, minmax(262.5px, 1fr));
  }

  .social-link {
    font-size: 3rem;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .projects-gallery {
    grid-template-columns: repeat(auto-fill, minmax(262.5px, 1fr));
  }
}
